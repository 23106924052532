exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alternatives-index-js": () => import("./../../../src/pages/alternatives/index.js" /* webpackChunkName: "component---src-pages-alternatives-index-js" */),
  "component---src-pages-alternatives-pingdom-js": () => import("./../../../src/pages/alternatives/pingdom.js" /* webpackChunkName: "component---src-pages-alternatives-pingdom-js" */),
  "component---src-pages-alternatives-statuscake-js": () => import("./../../../src/pages/alternatives/statuscake.js" /* webpackChunkName: "component---src-pages-alternatives-statuscake-js" */),
  "component---src-pages-alternatives-statuspage-js": () => import("./../../../src/pages/alternatives/statuspage.js" /* webpackChunkName: "component---src-pages-alternatives-statuspage-js" */),
  "component---src-pages-alternatives-uptimerobot-js": () => import("./../../../src/pages/alternatives/uptimerobot.js" /* webpackChunkName: "component---src-pages-alternatives-uptimerobot-js" */),
  "component---src-pages-free-tools-index-js": () => import("./../../../src/pages/freeTools/index.js" /* webpackChunkName: "component---src-pages-free-tools-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-agencies-js": () => import("./../../../src/pages/industry/agencies.js" /* webpackChunkName: "component---src-pages-industry-agencies-js" */),
  "component---src-pages-industry-ecommerce-js": () => import("./../../../src/pages/industry/ecommerce.js" /* webpackChunkName: "component---src-pages-industry-ecommerce-js" */),
  "component---src-pages-industry-saas-js": () => import("./../../../src/pages/industry/saas.js" /* webpackChunkName: "component---src-pages-industry-saas-js" */),
  "component---src-pages-integrations-index-js": () => import("./../../../src/pages/integrations/index.js" /* webpackChunkName: "component---src-pages-integrations-index-js" */),
  "component---src-pages-integrations-upcoming-js": () => import("./../../../src/pages/integrations/upcoming.js" /* webpackChunkName: "component---src-pages-integrations-upcoming-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-product-incident-management-js": () => import("./../../../src/pages/product/incident-management.js" /* webpackChunkName: "component---src-pages-product-incident-management-js" */),
  "component---src-pages-product-ssl-monitoring-js": () => import("./../../../src/pages/product/ssl-monitoring.js" /* webpackChunkName: "component---src-pages-product-ssl-monitoring-js" */),
  "component---src-pages-product-status-page-js": () => import("./../../../src/pages/product/status-page.js" /* webpackChunkName: "component---src-pages-product-status-page-js" */),
  "component---src-pages-product-website-monitoring-js": () => import("./../../../src/pages/product/website-monitoring.js" /* webpackChunkName: "component---src-pages-product-website-monitoring-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blogListTemplate.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-doc-template-js": () => import("./../../../src/templates/docTemplate.js" /* webpackChunkName: "component---src-templates-doc-template-js" */)
}

